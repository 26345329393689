import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';
// @ts-ignore
import pic1 from '../assets/images/pic01.png';
// @ts-ignore
import pic2 from '../assets/images/pic02.jpg';
// @ts-ignore
import pic3 from '../assets/images/pic03.jpg';
// @ts-ignore
import client1 from '../assets/logo-slider/chips.jpeg';
// @ts-ignore
import client2 from '../assets/logo-slider/skynetlogo.png';
// @ts-ignore
import client3 from '../assets/logo-slider/smart-planet.png';
// @ts-ignore
import client4 from '../assets/logo-slider/nhm.png';
// @ts-ignore
import client5 from '../assets/logo-slider/cms.jpg';
// @ts-ignore
import client6 from '../assets/logo-slider/crayon.png';
// @ts-ignore
import client7 from '../assets/logo-slider/ingram.png';
// @ts-ignore
import client8 from '../assets/logo-slider/rah.jpg';
// @ts-ignore
import client9 from '../assets/logo-slider/ivalue.jpg';

import config from '../../config';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
      
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            JR GLOBAL CONSULTANCY SERVICES
            <br />
          </h2>

          <p>
            We always strive for excellence and professionalism in everything we
            do and we always try to improve our knowledge.We will always be straightforward
            in all of our dealings and serve our customers with complete honesty no matter the consequence.
            <br />

          </p>
        </header>
        <ul className="icons major">
          <li>
            <Scroll type="id" element="cloud">
            <span className="icon solid fas fa-cloud major style1">
              <span className="label">Cloud</span>
            </span>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="code">
          <span className="icon solid fa-code major style3">
              <span className="label">Code</span>
              </span>
              </Scroll>
          </li>
          <li>
              <Scroll type="id" element="infra">
            <span className="icon solid fa-cogs major style2">
              <span className="label">Web</span>
              </span>
              </Scroll>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2 id='cloud'>CLOUD</h2>
          <em>"Everything is going to be connected to cloud and data... <br /> All of this will be mediated
            by software"</em>
          <p>
            Taking into consideration your current business needs and requirements and applying the extensive
            expertise in cloud computing platforms, We are authorized partner with vmware, Google Cloud and more.
            JR Global's IT team is ready to provide you with a range of cloud-specific services.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2 id='code'> APPLICATION </h2>
          <em>“The best way to get a project done faster is to start sooner” </em>
          <p>
            software technology A general term covering the development methods, programming languages,
            and tools to support them that may be used in the development of software.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2 id='infra'>IT-INFRASTRUCTURE</h2>
          <em>"Building infrastructure is our most powerful tool to create jobs and improve the playing field for all businesses"</em>
          <p>
            Our infrastructure knowledge runs deep so your business will reach greater heights.
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
         <a href='\Solutions'> <h2>ENTERPRISE SOLUTIONS</h2></a>
        </header>
        <ul className="features">
          <li className="icon solid fa-chalkboard-teacher">
            <h3>VMware on AWS</h3>
            <strong>Accelerate Your App and Cloud Transformation</strong>
            <p>
              Deliver modern apps at the speed your business demands and operate across the data center, the edge, and any cloud with VMware Cloud.
            </p>
          </li>
           <li className="icon fa-flag">
            <h3>CLOUD MIGRATION</h3>
            <strong>Seamless App Migration to Any Cloud</strong>
            <p>
              Take the fastest, lowest cost path to the cloud and accelerate application modernization with VMware Cloud migration solutions.
            </p>
          </li>
           <li className="icon solid fa-code">
            <h3>APP DEVELOPMENT</h3>
            <strong>Making the web safe for every user, anywhere</strong>
            <p>
              Technologies are evolving faster. To survive the competition,
              your applications needs to be built using a future ready technology.
            </p>
          </li>
           <li className="icon fa-paper-plane">
            <h3>END POINT DETECTION & RESPONSE</h3>
            <p>
              See more by connecting email, endpoints, servers, and cloud workloads with extended detection and response (XDR). Gain a broader perspective, better context, and the power
              to investigate and respond from a single place with an attack-centric view of an entire chain of events.
            </p>
          </li>
          <li className="icon solid fa-server">
            <h3>NETWORK & ENTERPRISE MONITORING</h3>
            <p>
              Enterprise network monitoring solution which monitors the health,
             availability, and performance of your network in real time. 
            </p>
          </li>
          <li className="icon fa-lightbulb">
            <h3>EMAIL SERVICES</h3>
            <p>
              Proactively secure the web with advanced, real-time threat defenses—full content
              inspection and in-line security scanning help mitigate risk and protect against malware.
            </p>
          </li>
           <li className="icon fa-lightbulb">
            <h3>SECURITY SOLUTIONS</h3>
            <p>
              Security in every layer of a hybrid cloud environment to help accelerate innovation and
              securely build, modernize or migrate mission critical workloads.
            </p>
          </li>
           <li className="icon fa-lightbulb">
            <h3>CYBER SECURITY</h3>
            <p>
              Application of technologies, processes and controls to protect systems,
              networks, programs, devices and data from cyber attacks.
            </p>
          </li>
        </ul>
      </div>
    </section>
    <section className="wrapper style5 special">
      <div className="inner">
       <header className="major">
          <h2>CLIENTS & SERVICE PARTNERS</h2>
        </header>
      
        <div className="slider align-center">
          <div className="slide-track">
            <div className="slide">
              <img src={client1} height="100" width="200" alt="" />
            </div>
            <div className="slide">
              <img src={client2} height="70" width="150" alt="" />
            </div>
            <div className="slide">
              <img src={client3} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client4} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client5} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client6} height="100" width="200" alt="" />
            </div>
            <div className="slide">
              <img src={client7} height="80" width="180" alt="" />
            </div>
            <div className="slide">
              <img src={client8} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client9} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client1} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client2} height="70" width="150" alt="" />
            </div>
            <div className="slide">
              <img src={client3} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client4} height="100" width="250" alt="" />
            </div>
            <div className="slide">
              <img src={client5} height="100" width="250" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>GET IN TOUCH WITH US</h2>
          <p>
            <strong>Mobile No.</strong> : 0771-3554780
            <br />
            <strong>Address </strong>: Lalganga Business Park, 506, Fifth Floor, Pachpedi Naka, Raipur, Chhattisgarh - 492013
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="/About" className="button fit primary">
              Our Blog
            </a>
          </li>
          <li>
            <a href="/#" className="button fit">
              Learn More
            </a>
          </li>
        </ul>
      </div>
    </section>

  </Layout>
);

export default IndexPage;
